import { Component, OnInit } from '@angular/core';
import mulheresData from '../../../../mulheres.json';
import { MulherDTO } from 'src/app/interface/mulher-interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mulher',
  templateUrl: './mulher.component.html',
  styleUrls: ['./mulher.component.css'],
})
export class MulherComponent implements OnInit {
  mulher?: MulherDTO;

  constructor(private route: ActivatedRoute) {
    this.getMulheres();
  }

  ngOnInit(): void {}

  getMulheres() {
    const nome = String(this.route.snapshot.paramMap.get('nome'));
    mulheresData.forEach((mulher) => {
      if (mulher.nome === nome) {
        this.mulher = mulher;
      }
    });
  }
}