<nav class="navbar navbar-expand-lg mavbar-light">
  <div class="container-fluid justify-content-end ">
    <button
      class="navbar-toggler me-sm-3 my-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav fs-5 gap-0 gap-md-3 pe-0 ms-md-4">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#projeto">PROJETO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#exposicao">EXPOSIÇÃO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#mulheres">MULHERES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#localizacao">LOCALIZAÇÃO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/equipe" >EQUIPE</a>
        </li>
      </ul>
    </div>
  </div>
</nav>


<section *ngIf="mulher" id="mulher">
  <div class="container-fluid">
    <div class="row">
      <h1 class="mt-3 mb-5 my-mx-0 title-font text-center">
        {{ mulher.nomeCompleto }}
      </h1>
      <div
        class="col-xl-5 text-center align-self-center d-grid"
        style="justify-items: center"
      >
        <video height="auto" class="img-fluid px-4" controls controlsList="nodownload" autoplay>
          <source src="{{ mulher.video }}" type="video/mp4"/>
        </video>
        <a
          href="https://www.instagram.com/{{ mulher.instagram }}"
          target="_blank"
          ><img
            src="../../../assets/svg/instagram-logo-pink.svg"
            class="logo-instagram d-xl-none me-4"
            alt="Instagram"
          />
          <span class="d-xl-none">@{{ mulher.instagram }}</span>
        </a>
      </div>
      <div class="col-xl-6 px-4 px-xl-0">
        <h3 class="mb-5 mt-2 pe-3 pe-xl-5">{{ mulher.texto }}</h3>
        <h4 class="pe-3 pe-xl-5 pb-4">
          <b>{{ mulher.textoDestaque }}</b>
        </h4>
        <a
          href="https://www.instagram.com/{{ mulher.instagram }}"
          target="_blank"
          class="gradient-text title-font fs-3"
          ><img
            src="../../../assets/svg/instagram-logo-pink.svg"
            class="logo-instagram d-none d-xl-inline me-4"
            alt="Instagram"
          /><span class="d-none d-xl-inline">@{{ mulher.instagram }}</span></a
        >
      </div>
    </div>
  </div>
</section>
