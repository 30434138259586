import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MulherComponent } from './pages/mulher/mulher.component';
import { EquipeComponent } from './pages/equipe/equipe.component';

const routes: Routes = [
  {
    path:'', component: HomepageComponent,
  },
  {
    path: 'equipe', component: EquipeComponent
  },
  {
    path: ':nome', component: MulherComponent 
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
