import { Component, OnInit } from '@angular/core';
import mulheresData from '../../../../mulheres.json'
import { MulherDTO } from 'src/app/interface/mulher-interface';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  mulheres: MulherDTO[] = mulheresData;

  defaultImage = '/assets/images/BG_Blur_Site.png';


  ngOnInit(): void {
  }

}
