<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-12 responsive">
        <img src="../../../assets/images/parceiros/aflora-cultural-logo.png" alt="Aflora Cultural - Logo">
        <img src="../../../assets/images/parceiros/colmeia-social-logo.png" alt="Colmeia Social - Logo">
        <img src="../../../assets/images/parceiros/central-plaza-logo.png" alt="Central Plaza - Logo">
      </div>
    </div>
  </div>
</footer>
