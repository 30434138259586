<div class="container-fluid p-0 header" style="overflow: hidden">
  <div class="row">
    <div class="col-12 p-0 -lg-3">
      <nav class="navbar navbar-expand-lg mavbar-light">
        <div class="container-fluid justify-content-end">
          <button
            class="navbar-toggler me-sm-3 my-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav fs-5 gap-0 gap-md-3 pe-0 ms-md-4">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#">HOME</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#projeto">PROJETO</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#exposicao">EXPOSIÇÃO</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#mulheres">MULHERES</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#localizacao">LOCALIZAÇÃO</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/equipe">EQUIPE</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<div class="center container">
  <div>
    <div class="col-12 d-block d-sm-flex p-3 p-lg-3">
      <div class="col-12 mt-2 padding">
        <div class="">
          <h2 class="fs-3 title-font">Equipe</h2>
        </div>
      </div>
    </div>

    <!-- Andrea Moreira -->
    <div class="col-12 d-block d-sm-flex p-3 p-lg-3">
      <div class="col-12 mt-4 padding">
        <div class="">
          <h2 class="fs-3">Andrea Moreira</h2>
          <h3>Idealizadora do projeto</h3>
          <p class="mt-0 fs-5 mb-1 format_align_justify">
            Produtora cultural e especialista em Sustentabilidade e direitos
            humanos, Andrea criou a Aflora Cultural a partir de sua experiência
            de dez anos na gestão executiva de parcerias para implementação de
            projetos socioculturais envolvendo empresas incentivadoras de
            impostos e proponentes, cujas iniciativas geram vivências artísticas
            e culturais afetivas, conscientização e desenvolvimento da
            sociedade. A Aflora Cultural nasce com o propósito de desenvolver
            projetos socioculturais personalizados de alto impacto social,
            alinhados aos Objetivos do Desenvolvimento Sustentável e ao
            fortalecimento das políticas públicas.
          </p>
        </div>
      </div>
    </div>
    <!-- Equipe técnica -->
    <div class="col-12 d-block d-sm-flex p-3 p-lg-3">
      <div class="col-12 mt-4 padding">
        <div class="">
          <h4>Equipe técnica:</h4>
          <p class="mt-0 fs-5 mb-5 format_align_justify">
            <li><span>Direção de Arte:</span> Milena Andrade Gavioli</li>
            <li>
              <span>Produção artística:</span> Luan Willik, Mariana Denegri,
              Mariana Araújo e <br />
              Milena Gavioli
            </li>
            <li>
              <span>Fotografia e vídeo:</span> Luan Willik e Cauê Guidorzi
            </li>
            <li>
              <span>Desenvolvimento tecnológico:</span> Arthur Gebara e Felipe
              Alves
            </li>
            <li>
              <span>Texto:</span> Andrea Moreira, Sarah Almeida e Victoria
              Santana
            </li>
            <li><span>Supervisão:</span> Bruno Rosseti e Nielson Fortunato</li>
          </p>
        </div>
      </div>
    </div>

    <div class="col-3">
      <img src="../../../assets/images/equipe-flor.png" alt="" class="w-100" />
    </div>
  </div>
</div>
