<app-navbar></app-navbar>
<!-- banner -->
<img
  src="/assets/images/banner.jpeg"
  class="w-100 img-fluid"
  alt="Banner - Real ao Artificial"
/>

<!-- A exposição -->
<section id="projeto">
  <div class="bg-projeto">
    <div class="d-xl-flex justify-content-md-between gap-5">
      <div class="align-self-center d-none d-xl-block">
        <img
          src="/assets/images/flor-expo-separada.png"
          alt="Flores Tecnologicas"
        />
      </div>
      <div class="text-md-start">
        <div class="pt-4 ps-4 fs-1 left-side d-flex">
          <div>
            <h2 class="title-color fs-2 mb-2 title-font">A Exposição</h2>
            <div class="">
              <div class="line-dot mb-4"></div>

            </div>
          </div>
        </div>
        <h5 class="fs-3 p-4 me-0 pe-xl-5 me-xl-5">
          A exposição do Real ao Artificial, é criada a partir da tecnologia,
          contudo,  traz uma abordagem humana em que sete  mulheres
          empreendedoras olharam para a realidade com base em histórias,
          programaram o futuro em uma linha de tempo  e dão um salto corajoso de
          20 anos. <br />
          <br />
          Entre o período de 2024 e 2044, as mulheres além de se defrontarem com
          o envelhecimento, descobrem que a jornada planejada foi leve e
          divertida, que a inteligência artificial incorporada às suas rotinas
          deixou suas vidas mais confortáveis e principalmente que seus sonhos e
          legado foram concretizados. <br />
          <br />
          Uma exposição que dá a oportunidade de viver o presente e sentir o
          futuro, talvez um jeito de contribuir para a redução da ansiedade,
          haja vista, que o futuro é inevitável e pode ser muito bom.
        </h5>
      </div>
    </div>
  </div>
  <!-- Instagram -->
  <div
    class="text-center fs-2 pt-4 pb-2"
    style="
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    "
  >
    <p class="fs-3 px-3 title-font">
      Acompanhe nosso perfil no Instagram
      <span class="bg-gradient"
        ><img
          src="/assets/images/instagram-logo.png"
          width="40px"
          alt="Logo Instagram"
        />
      </span>
      <a
        href="https://www.instagram.com/afloracultural/"
        target="__blank"
        class="gradient-text title-font fs-3"
      >
        @afloracultural</a
      >
    </p>
  </div>
</section>

<div class="line" style="height: 10px"></div>
<!-- Mulheres em 2044 -->

<section id="mulheres" class="py-5">
  <div class="container">
  <div class="row">
    <div class="text-center">
      <h2 class="title-color title-font fs-3 mb-0">Mulheres em 2044</h2>
      <span class="fs-4"
        >Quanta tecnologia há no futuro do empreendedorismo feminino?</span
      >
    </div>
  </div>
  <div class="content-images">
    <div class="row">
      <div class="col-md-3 px-2 px-md-1" *ngFor="let mulher of mulheres">
        <div class="image">
          <a routerLink="/{{ mulher.nome }}">
            <img
              class="image__img img-fluid"
              src="{{ mulher.imagemFrente }}"
              alt="{{ mulher.nomeCompleto }}"
            />
            <div class="image__overlay">
              <img src="{{mulher.imagemHover}}" alt="{{mulher.nome}}" class="image__overlay">
              <div class="image__title">{{ mulher.nomeCompleto }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<!-- Clique aqui -->
<section id="exposicao">
  <div class="bg-expo d-flex align-items-center justify-content-center">
    <div class="text-center text-white">
      <h1 class="title-font fs-3 pb-3 px-3 ">
        Quanta tecnologia há no futuro <br />
        do empreendedorismo feminino?
      </h1>
      <a href="https://tour.dorealaoartificial.com.br/" target="_blank" class="expo-btn btn"><span class="fs-4">CLIQUE AQUI</span></a>
    </div>
  </div>
</section>

<!-- Chamada da Exposição -->

<section id="localizacao">
  <div class="row">
    <div class="col-md-5">
      <img
        src="/assets/images/arte-exposicao2.jpg"
        class="h-100 w-100"
        alt="Exposição Virtual - Real ao Artificial"
        style="box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"
      />
    </div>
    <div class="col-md-7 pb-5 px-4">
      <div class="content">
        <div class="content-text pb-3">
          <h3 class="fs-3 title-color title-font pe-4">
            do Real ao Artificial, é uma exposição gratuita em que é possível
            viver o presente e experimentar o futuro simultaneamente com o uso
            da tecnologia. Uma reflexão sobre vida, carreira e sociedade.
          </h3>
        </div>
        <div class="mt-4">
          <h4><b>Período: </b><span>5 de março à 16 de abril</span></h4>
          <br />
          <h4><b>Horário: </b><span>10h às 22h</span></h4>
          <br />
          <h4>
            <b>Local: </b
            ><span
              ><a
                href="https://g.co/kgs/xnsDdrD"
                target="_blank"
                class="title-color"
                ><b>Central Plaza Shopping</b></a
              ></span
            >
          </h4>
          <h5>
            <img
              src="/assets/svg/localization.svg"
              class="svg-localization"
              alt="SVG Localização"
            />
            Av. Dr. Francisco Mesquita, 1.000 - Jardim Ibtirama, São Paulo
          </h5>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Patrocinadores -->
<app-footer></app-footer>
