<nav class="navbar navbar-expand-lg mavbar-light">
  <div class="container-fluid  justify-content-end ">
    <button
      class="navbar-toggler me-sm-3 my-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav fs-5 gap-0 gap-md-3 pe-0 ms-md-4">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#projeto">PROJETO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#exposicao">EXPOSIÇÃO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#mulheres">MULHERES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#localizacao">LOCALIZAÇÃO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/equipe" >EQUIPE</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
